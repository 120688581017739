import React from 'react'
import PropTypes from 'prop-types'

const IntroAlt = ({
  children,
  showTitle,
  showSubTitle,
  bgImage,
  colType,
  title,
  subTitle,
  classNames,
}) => {
  classNames = 'intro-section ' + classNames
  return (
    <div className={classNames}>
      <div className="two-col">
        <div
          className="left-col hidden-mobile"
          style={{ backgroundImage: `url('${bgImage}')` }}
        />
        <div className={colType}>
          {showTitle && (
            <div className="title-holder">
              <h1>{title}</h1>
              {showSubTitle && <p className="sub-title">{subTitle}</p>}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}

IntroAlt.propTypes = {
  children: PropTypes.node,
  showTitle: PropTypes.bool,
  showSubTitle: PropTypes.bool,
  bgImage: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  classNames: PropTypes.string,
}

IntroAlt.defaultProps = {
  showTitle: true,
  showSubTitle: true,
  bgImage: '/images/dad-son-searching.jpg',
  colType: 'right-col',
  title: 'Get an affordable Medicare Supplement Plan.',
  subTitle:
    'With Medicare Companion, expand your Medicare coverage. Plans offer additional coverage for prescription drugs, dental and vision.',
  classNames: '',
}

export default IntroAlt
